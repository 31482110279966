import { addMinutes } from 'date-fns';
import { SubmitHandler } from 'react-hook-form';
import { DecimalNumber } from '@api/baseAPI/types';
import { PaymentMethod, VirtualAccountPaymentMethod } from '@api/order/types';
import { useCommonStore } from '@hooks/storage';
import { formatCurrency } from '@utils/number';
import CheckoutFormDialog from './CheckoutFormDialog';
import CreditCardCvnFormDialog from './CreditCardCvnFormDialog';
import { FormProps as CvnFormProps } from './CreditCardCvnFormDialog/forms/useForm';
import QRFormDialog from './QRFormDialog';
import VirtualAccountDialog from './VirtualAccountDialog';
import XenditCreditCardPaymentDialog from './XenditCreditCardPaymentDialog';

interface Props {
  paymentMethod: PaymentMethod;
  orderId?: string;
  orderCreatedAt?: string;
  orderTotalAmount?: DecimalNumber;
  onSubmitCvn: SubmitHandler<CvnFormProps>;
  xenditPaymentUrl?: string;
  onCloseXenditPaymentDialog: () => void;
  qrCode: string;
  onCloseQrDialog: () => void;
  onCancelPayment: () => void;
  checkoutUrl?: string;
  onCloseCheckoutDialog: () => void;
  virtualAccountNumber: string;
  onCloseVirtualAccountDialog: () => void;
  isLoading?: boolean;
  openCvnInputDialog: boolean;
  onCloseCvnDialog: () => void;
  isOpenBill: boolean;
}

const OrderPaymentDialog = ({
  paymentMethod,
  orderId,
  orderCreatedAt = '',
  orderTotalAmount,
  onSubmitCvn,
  xenditPaymentUrl,
  onCloseXenditPaymentDialog,
  qrCode,
  onCloseQrDialog,
  onCancelPayment,
  checkoutUrl,
  onCloseCheckoutDialog,
  virtualAccountNumber,
  onCloseVirtualAccountDialog,
  isLoading,
  openCvnInputDialog,
  onCloseCvnDialog,
  isOpenBill,
}: Props) => {
  const { storageState } = useCommonStore();
  // NOTE: Intentionally don't memoized this function so we can get new Date everytime this component is rendered.
  const getValidExpiredDate = () => {
    /**
     * NOTE: We can only use createdAt as the benchmark for order expiry when the order type is online ordering.
     * If the order type is merged_open_bill_order, we can't use createdAt since calling api closing will always return the same merged_open_bill_order instead of recreating new merged_open_bill_order.
     */
    if (orderCreatedAt && !isOpenBill) {
      return new Date(orderCreatedAt);
    }
    return new Date();
  };

  const expiredAt = addMinutes(getValidExpiredDate(), 15);

  return (
    <>
      <XenditCreditCardPaymentDialog url={xenditPaymentUrl} onClose={onCloseXenditPaymentDialog} />
      <QRFormDialog
        qr={qrCode}
        paymentMethod={paymentMethod}
        onClose={onCloseQrDialog}
        onCancelPayment={onCancelPayment}
      />
      <CheckoutFormDialog
        paymentMethod={paymentMethod}
        open={!!checkoutUrl || (paymentMethod?.type === 'ovo' && !!orderId)}
        checkoutUrl={checkoutUrl}
        onClose={onCloseCheckoutDialog}
        expiredAt={expiredAt}
        onCancelPayment={onCancelPayment}
      />
      <VirtualAccountDialog
        paymentMethod={
          paymentMethod?.type === 'va-transfer' ? (paymentMethod as VirtualAccountPaymentMethod) : undefined
        }
        expiredAt={expiredAt}
        virtualAccountNumber={virtualAccountNumber}
        onClose={onCloseVirtualAccountDialog}
        onCancelPayment={onCancelPayment}
        totalPrice={formatCurrency(orderTotalAmount, storageState.currency)}
      />
      <CreditCardCvnFormDialog
        onSubmit={onSubmitCvn}
        isLoading={isLoading}
        open={openCvnInputDialog}
        handleClose={onCloseCvnDialog}
      />
    </>
  );
};

export default OrderPaymentDialog;
